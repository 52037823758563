<!--
 * @Author: 候怀烨
 * @Date: 2020-11-25 15:25:18
 * @LastEditTime: 2021-04-26 09:54:26
 * @LastEditors: Please set LastEditors
 * @Description: 折线图
 * @FilePath: \sd-vue-admin\src\views\index\components\EchartsZhe.vue
-->
<template>
  <div>
    <div class="ech-row">
      <div id="myChart" style="width: 70%; height: 400px"></div>
      <div style="width: 26%; height: 400px">
        <!-- <h3>收款清账</h3> -->
        <div class="yinying">
          <h3>本周</h3>
          <div class="ech-text">
            <p>{{ dataech.pay_amount }}</p>
            <p>收款</p>
          </div>
          <div class="ech-text" style="color: red">
            <p>{{ dataech.left_amount }}</p>
            <p>欠款</p>
          </div>
          <div class="ech-text" style="color: #3ecd7f">
            <p>{{ dataech.clear_amount }}</p>
            <p>清欠</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  const colorList = ['#5697F1', '#FF960F', '#fe9a8b', '#F56948', '#9E87FF']
  export default {
    data() {
      return {
        dataech: {},
        option: {
          backgroundColor: '#fff',
          title: {
            text: '本周业绩',
            textStyle: {
              fontSize: 18,
              fontWeight: 600,
            },
            left: 'left',
            top: '5%',
          },
          legend: {
            icon: 'circle',
            top: '5%',
            right: '5%',
            itemWidth: 6,
            itemGap: 20,
            textStyle: {
              color: '#556677',
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              label: {
                show: true,
                backgroundColor: '#fff',
                color: '#556677',
                borderColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(0,0,0,0)',
                shadowOffsetY: 0,
              },
              lineStyle: {
                width: 0,
              },
            },
            backgroundColor: '#fff',
            textStyle: {
              color: '#5c6c7c',
            },
            padding: [10, 10],
            extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
          },
          grid: {
            top: '15%',
          },
          xAxis: [
            {
              type: 'category',
              data: [],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 0,
                textStyle: {
                  color: '#556677',
                },
                // 默认x轴字体大小
                fontSize: 18,
                // margin:文字到x轴的距离
                margin: 15,
              },
              axisPointer: {
                label: {
                  // padding: [11, 5, 7],
                  padding: [0, 0, 10, 0],
                  /*
      除了padding[0]建议必须是0之外，其他三项可随意设置

      和CSSpadding相同，[上，右，下，左]

      如果需要下边线超出文字，设左右padding即可，注：左右padding最好相同

      padding[2]的10:

      10 = 文字距下边线的距离 + 下边线的宽度

      如：UI图中文字距下边线距离为7 下边线宽度为2

      则padding: [0, 0, 9, 0]

                  */
                  // 这里的margin和axisLabel的margin要一致!
                  margin: 15,
                  // 移入时的字体大小
                  fontSize: 18,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff', // 0% 处的颜色
                      },
                      {
                        // offset: 0.9,
                        offset: 0.86,
                        /*
  0.86 = （文字 + 文字距下边线的距离）/（文字 + 文字距下边线的距离 + 下边线的宽度）

                          */
                        color: '#fff', // 0% 处的颜色
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#33c0cd', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisLabel: {
                textStyle: {
                  color: '#556677',
                },
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [],
        },
      }
    },
    mounted() {
      this.handlerecarts()
    },
    methods: {
      handlerecarts() {
        postAction('/baseAdmin/main/month', {})
          .then((res) => {
            console.log(res, '图表')
            this.dataech = res.data
            var b = {
              name: '销售额',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 5,
              },
              itemStyle: {
                normal: {
                  color: colorList[0],
                  borderColor: colorList[0],
                },
              },
            }
            const d = {
              name: '订单额',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 5,
              },
              itemStyle: {
                normal: {
                  color: colorList[1],
                  borderColor: colorList[1],
                },
              },
            }

            b.data = res.data.bill_money

            this.option.series.push(b)

            d.data = res.data.order_money
            this.option.series.push(d)

            this.option.xAxis[0].data.push(...res.data.week)

            this.myChartOne = this.$echarts.init(
              document.getElementById('myChart')
            )
            this.myChartOne.setOption(this.option)
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style>
  .ech-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .yinying {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .ech-text {
    align-items: center;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
  }
</style>
