var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-between" } },
    [
      _c("div", { staticClass: "haerd-bot" }, [
        _c("div", { staticClass: "row-bot" }, [
          _c("img", {
            attrs: {
              src: "http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin1-icon-.png",
            },
          }),
          _c("div", { staticClass: "col-bot" }, [
            _c("span", [_vm._v("员工：" + _vm._s(_vm.datalist.user_count))]),
            _c("span", [_vm._v("部门：" + _vm._s(_vm.datalist.depart_count))]),
          ]),
        ]),
        _c("div", { staticClass: "row-bot" }, [
          _c("img", {
            attrs: {
              src: "http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/cangku-icon-.png",
            },
          }),
          _c("div", { staticClass: "col-bot" }, [
            _c("span", [_vm._v("仓库：" + _vm._s(_vm.datalist.depot_count))]),
            _c("span", [_vm._v("车辆：" + _vm._s(_vm.datalist.car_count))]),
          ]),
        ]),
        _c("div", { staticClass: "row-bot" }, [
          _c("img", {
            attrs: {
              src: "http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/yuangong-icon.png",
            },
          }),
          _c("div", { staticClass: "col-bot" }, [
            _c("span", [_vm._v("商品：" + _vm._s(_vm.datalist.goods_count))]),
            _c("span", [_vm._v("品牌：" + _vm._s(_vm.datalist.brand_count))]),
          ]),
        ]),
        _c("div", { staticClass: "row-bot" }, [
          _c("img", {
            attrs: {
              src: "http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin-icon.png",
            },
          }),
          _c("div", { staticClass: "col-bot" }, [
            _c("span", [
              _vm._v("客户：" + _vm._s(_vm.datalist.customer_count)),
            ]),
            _c("span", [_vm._v("渠道：" + _vm._s(_vm.datalist.channel_count))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "psdds" }, [_c("pop-ups")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }