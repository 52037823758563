<!--
 * @Author: your name
 * @Date: 2020-11-24 18:38:39
 * @LastEditTime: 2020-11-25 14:53:48
 * @LastEditors: Please set LastEditors
 * @Description: 首页基础数据
 * @FilePath: \sd-vue-admin\src\views\index\components\HaerdBot.vue

http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin1-icon-.png
http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/cangku-icon-.png
http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/yuangong-icon.png
http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin-icon.png
-->
<template>
  <div style="display: flex; justify-content: space-between">
    <div class="haerd-bot">
      <div class="row-bot">
        <img
          src="http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin1-icon-.png"
        />
        <div class="col-bot">
          <span>员工：{{ datalist.user_count }}</span>
          <span>部门：{{ datalist.depart_count }}</span>
        </div>
      </div>
      <div class="row-bot">
        <img
          src="http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/cangku-icon-.png"
        />
        <div class="col-bot">
          <span>仓库：{{ datalist.depot_count }}</span>
          <span>车辆：{{ datalist.car_count }}</span>
        </div>
      </div>
      <div class="row-bot">
        <img
          src="http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/yuangong-icon.png"
        />
        <div class="col-bot">
          <span>商品：{{ datalist.goods_count }}</span>
          <span>品牌：{{ datalist.brand_count }}</span>
        </div>
      </div>
      <div class="row-bot">
        <img
          src="http://shangdan.oss-cn-beijing.aliyuncs.com/public/mini/shangdan-v1/shangpin-icon.png"
        />
        <div class="col-bot">
          <span>客户：{{ datalist.customer_count }}</span>
          <span>渠道：{{ datalist.channel_count }}</span>
        </div>
      </div>
    </div>
    <div class="psdds">
      <pop-ups></pop-ups>
    </div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import PopUps from './PopUps.vue'
  export default {
    components: {
      PopUps,
    },
    data() {
      return {
        datalist: {},
      }
    },
    mounted() {
      this.handlerjichu()
    },
    methods: {
      handlerjichu() {
        postAction('/baseAdmin/main/base', {})
          .then((res) => {
            console.log(res, '')
            this.datalist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style scoped>
  .haerd-bot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    padding: 30px;
    background: #ffffff;
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .row-bot {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .col-bot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 20px;
  }
  .psdds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px;
    color: rgb(12, 133, 247);
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
</style>
