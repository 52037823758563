var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "font-size": "20px" },
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v(" 开帐向导 ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.username + ",您好！欢迎使用商单4.0企业管理系统",
            visible: _vm.dialogVisible,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("p", [_vm._v("为了方便您更好地使用本系统，请先配置以下项目：")]),
            _c(
              "div",
              { staticClass: "flexs" },
              [
                _c(
                  "el-table",
                  { attrs: { stripe: "", border: "", data: _vm.tableData } },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "主要步骤",
                        prop: "name",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _c("div", [
                                row.url
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: row.url,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(row.name) + " ")]
                                    )
                                  : $index == 4
                                  ? _c("div")
                                  : _c(
                                      "a",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.batchImport(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(row.name) + " ")]
                                    ),
                              ]),
                              $index == 2 || $index == 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.batchImport(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" 批量导入 "),
                                      _c("i", {
                                        staticClass: "el-icon-upload",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              $index == 0 ? _c("div") : _vm._e(),
                              $index == 1 ? _c("div") : _vm._e(),
                              $index == 3
                                ? _c("div", [
                                    _vm._v(
                                      " (导入前请先创建好供应商、仓库和商品) "
                                    ),
                                  ])
                                : _vm._e(),
                              $index == 4
                                ? _c("div", [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.batchImport(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 导入客户欠款 ")]
                                    ),
                                    _c("div", [
                                      _vm._v("(导入前请先创建好客户、业务员)"),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "相关事项",
                        width: "",
                        prop: "xaingqing",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return _vm._l(row.xaingqing, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item,
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "text-align": "left",
                                  },
                                },
                                [
                                  $index == 4
                                    ? _c("div")
                                    : _c("div", {
                                        domProps: { innerHTML: _vm._s(item) },
                                      }),
                                ]
                              )
                            })
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.notshow } },
                [_vm._v("不显示此页")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.suubmi } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
          _c("import-tpl", { ref: "importTpl" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }