<!--
 * @Author: 候怀烨
 * @Date: 2020-11-24 15:18:53
 * @LastEditTime: 2021-04-26 10:00:19
 * @LastEditors: Please set LastEditors
 * @Description: 今日业绩
 * @FilePath: \sd-vue-admin\src\views\index\components\HaerdLeft.vue
-->
<template>
  <div class="col-f">
    <div class="psdd">
      <el-row type="flexs" class="row-bg">
        <span>今日销售</span>
      </el-row>
      <div class="text-psa">
        <div class="const">
          <span class="font-w">{{ list.order_count }}</span>
          <div class="flexs">
            <div class="dian"></div>
            <h4>销售单量</h4>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="const">
          <span class="font-w">{{ list.order_money }}</span>
          <div class="flexs">
            <div class="dian"></div>
            <h4>销售金额</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="psdd">
      <el-row type="flexs" class="row-bg">
        <span>今日拜访</span>
      </el-row>
      <div class="text-psa">
        <div class="const">
          <span class="font-w">{{ list.create_count }}</span>
          <div class="flexs">
            <div class="dian"></div>
            <h4>新增客户</h4>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="const">
          <span class="font-w">{{ list.visit_count }}</span>
          <div class="flexs">
            <div class="dian"></div>
            <h4>拜访客户</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="psdds" @click="handleramountto">
      <span>今日实收</span>
      <!--      <pop-ups></pop-ups>-->
      <div class="consts">
        <span class="font-w">{{ list.sum_count }}</span>
        <div class="flexs">
          <div class="dian"></div>
          <span>收款合计</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import PopUps from './PopUps.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      // PopUps,
    },
    data() {
      return {
        list: {},
      }
    },
    mounted() {
      this.handleryeji()
    },
    methods: {
      handleramountto() {
        this.$router.push('/report/manage/dailySale')
      },
      handleryeji() {
        postAction('/baseAdmin/main/today', {})
          .then((res) => {
            console.log(res, '业绩成功')
            this.list = res.data
          })
          .catch((err) => {
            console.log(err, '业绩失败')
          })
      },
    },
  }
</script>

<style scoped>
  .psdd {
    display: flex;
    flex-direction: column;
    width: 37%;
    padding: 10px;
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .psdds {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    width: 16%;
    padding: 10px;
    /*color: rgb(12, 133, 247);*/
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .dian {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background: #86c55b;
    border-radius: 50%;
  }
  .text-psa {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
  }
  .flexs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .const {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
  }
  .consts {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    padding: 0px 0px;
  }
  .el-divider--vertical {
    height: 80px;
  }
  .font-w {
    font-size: 30px;
    font-weight: 600;
  }
  .col-f {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
