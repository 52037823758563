var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "withdt" },
      [
        _c("h3", [_vm._v("待办事项")]),
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "rowsd",
              on: {
                click: function ($event) {
                  return _vm.handleGoRoute(item)
                },
              },
            },
            [
              _c("span", { staticClass: "text-tit" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("span", { staticClass: "text-color" }, [
                _vm._v(_vm._s(item.number)),
              ]),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "mglg" },
      [
        _c("h3", [_vm._v("经营预警")]),
        _vm._l(_vm.lists, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "rowsd",
              on: {
                click: function ($event) {
                  return _vm.handleGoRoute(item)
                },
              },
            },
            [
              _c("span", { staticClass: "text-tit" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("span", { staticClass: "text-color" }, [
                _vm._v(_vm._s(item.number)),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }