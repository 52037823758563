<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 14:05:44
 * @LastEditTime: 2021-04-26 09:35:18
 * @LastEditors: Please set LastEditors
 * @Description: 首页
 * @FilePath: \sd-vue-admin\src\views\index\index.vue
-->
<template>
  <div v-loading="loading" class="index-container" :class="{ bg: !show }">
    <div v-if="show" class="flex">
      <div class="flex-left">
        <div>
          <haerd-left></haerd-left>
        </div>
        <div style="width: 100%; padding: 20px 0px">
          <echarts-zhe></echarts-zhe>
        </div>
        <div>
          <div class="title-tobsd">
            <div class="title-tob">
              <h3>基础数据</h3>
              <!-- <pop-ups></pop-ups> -->
            </div>
            <div style="width: 100%">
              <haerd-bot></haerd-bot>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-rigit">
        <haerd-rigth></haerd-rigth>
      </div>
    </div>
    <div v-else class="info">欢迎使用商单公司数字化管理系统</div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  // import PopUps from './components/PopUps.vue'
  import HaerdLeft from './components/HaerdLeft.vue'
  import HaerdRigth from './components/HaerdRigth.vue'
  import HaerdBot from './components/HaerdBot.vue'
  import EchartsZhe from './components/EchartsZhe.vue'
  // 是否显示工作台接口 200显示
  import { showIndex } from '@/api/showIndex'
  export default {
    name: 'Index',
    components: {
      // PopUps,
      HaerdLeft,
      HaerdRigth,
      HaerdBot,
      EchartsZhe,
    },
    data() {
      return {
        loading: false,
        show: false,
      }
    },
    created() {
      this.loading = true
      console.log('asd')
      showIndex().then((res) => {
        console.log('res', res)
        if (res.data) {
          this.show = true
        } else {
          this.show = false
        }
        this.loading = false
      })
      let nowTime = new Date().getTime() // 1650459600000
      if (nowTime < 1650459600000) {
        this.$notify.info({
          title: '通知',
          dangerouslyUseHTMLString: true,
          message:
            '<strong style="color:red">今晚21:00至23:00服务器升级维护，届时请暂停使用本系统后台和手机端！给您造成不便，敬请谅解</strong>',
          duration: 0,
        })
      }
    },
    methods: {},
  }
</script>
<style lang="scss" scoped>
  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .flex-left {
      width: 75%;
    }
    .flex-rigit {
      width: 20%;
    }
  }
  .title-tob {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .title-tobsd {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url('~@/assets/bg.png') no-repeat !important;
    background-size: 100% 100% !important;
  }
  .info {
    width: 100%;
    height: 100%;
    font-size: 36px;
    text-align: center;
  }
</style>
