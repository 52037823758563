var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-f" }, [
    _c(
      "div",
      { staticClass: "psdd" },
      [
        _c("el-row", { staticClass: "row-bg", attrs: { type: "flexs" } }, [
          _c("span", [_vm._v("今日销售")]),
        ]),
        _c(
          "div",
          { staticClass: "text-psa" },
          [
            _c("div", { staticClass: "const" }, [
              _c("span", { staticClass: "font-w" }, [
                _vm._v(_vm._s(_vm.list.order_count)),
              ]),
              _vm._m(0),
            ]),
            _c("el-divider", { attrs: { direction: "vertical" } }),
            _c("div", { staticClass: "const" }, [
              _c("span", { staticClass: "font-w" }, [
                _vm._v(_vm._s(_vm.list.order_money)),
              ]),
              _vm._m(1),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "psdd" },
      [
        _c("el-row", { staticClass: "row-bg", attrs: { type: "flexs" } }, [
          _c("span", [_vm._v("今日拜访")]),
        ]),
        _c(
          "div",
          { staticClass: "text-psa" },
          [
            _c("div", { staticClass: "const" }, [
              _c("span", { staticClass: "font-w" }, [
                _vm._v(_vm._s(_vm.list.create_count)),
              ]),
              _vm._m(2),
            ]),
            _c("el-divider", { attrs: { direction: "vertical" } }),
            _c("div", { staticClass: "const" }, [
              _c("span", { staticClass: "font-w" }, [
                _vm._v(_vm._s(_vm.list.visit_count)),
              ]),
              _vm._m(3),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "psdds", on: { click: _vm.handleramountto } }, [
      _c("span", [_vm._v("今日实收")]),
      _c("div", { staticClass: "consts" }, [
        _c("span", { staticClass: "font-w" }, [
          _vm._v(_vm._s(_vm.list.sum_count)),
        ]),
        _vm._m(4),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexs" }, [
      _c("div", { staticClass: "dian" }),
      _c("h4", [_vm._v("销售单量")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexs" }, [
      _c("div", { staticClass: "dian" }),
      _c("h4", [_vm._v("销售金额")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexs" }, [
      _c("div", { staticClass: "dian" }),
      _c("h4", [_vm._v("新增客户")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexs" }, [
      _c("div", { staticClass: "dian" }),
      _c("h4", [_vm._v("拜访客户")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexs" }, [
      _c("div", { staticClass: "dian" }),
      _c("span", [_vm._v("收款合计")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }