var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ech-row" }, [
      _c("div", {
        staticStyle: { width: "70%", height: "400px" },
        attrs: { id: "myChart" },
      }),
      _c("div", { staticStyle: { width: "26%", height: "400px" } }, [
        _c("div", { staticClass: "yinying" }, [
          _c("h3", [_vm._v("本周")]),
          _c("div", { staticClass: "ech-text" }, [
            _c("p", [_vm._v(_vm._s(_vm.dataech.pay_amount))]),
            _c("p", [_vm._v("收款")]),
          ]),
          _c(
            "div",
            { staticClass: "ech-text", staticStyle: { color: "red" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.dataech.left_amount))]),
              _c("p", [_vm._v("欠款")]),
            ]
          ),
          _c(
            "div",
            { staticClass: "ech-text", staticStyle: { color: "#3ecd7f" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.dataech.clear_amount))]),
              _c("p", [_vm._v("清欠")]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }