<!--
 * @Author: 候怀烨
 * @Date: 2020-11-24 17:03:33
 * @LastEditTime: 2021-04-25 16:54:50
 * @LastEditors: Please set LastEditors
 * @Description: 重点关注
 * @FilePath: \sd-vue-admin\src\views\index\components\HaerdRigth.vue
-->
<template>
  <div>
    <div class="withdt">
      <h3>待办事项</h3>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="rowsd"
        @click="handleGoRoute(item)"
      >
        <span class="text-tit">{{ item.name }}</span>
        <span class="text-color">{{ item.number }}</span>
      </div>
    </div>
    <div class="mglg">
      <h3>经营预警</h3>
      <div
        v-for="(item, index) in lists"
        :key="index"
        class="rowsd"
        @click="handleGoRoute(item)"
      >
        <span class="text-tit">{{ item.name }}</span>
        <span class="text-color">{{ item.number }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        list: [],
        lists: [],
      }
    },
    mounted() {
      this.handlerZhongdian()
    },
    methods: {
      handlerZhongdian() {
        postAction('/baseAdmin/main/notice', {})
          .then((res) => {
            console.log(res, '真实数据成功')
            this.list = res.data.notice
            this.lists = res.data.waring
          })
          .catch((err) => {
            console.log(err, '失败数据')
          })
      },
      handleGoRoute(item) {
        console.log('item', item)
        // this.$router.push(item.router)
        let url = this.$router.resolve(item.router)
        console.log(url)

        window.open(url.href, '_blank')
      },
    },
  }
</script>

<style>
  .rowsd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
  }
  .withdt {
    width: 100%;
    padding: 20px;
    border-radius: 1%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .text-tit {
    font-size: 18px;
    font-weight: 600;
  }
  .text-color {
    font-size: 18px;
    font-weight: 600;
    color: red;
    text-decoration: underline;
  }
  .rowsd:hover {
    cursor: pointer;
  }
  .mglg {
    width: 100%;
    padding: 20px;
    margin-top: 40px;
    border-radius: 1%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22), 0 0 0 rgba(0, 0, 0, 0.04);
  }
</style>
