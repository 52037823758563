var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "index-container",
      class: { bg: !_vm.show },
    },
    [
      _vm.show
        ? _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "flex-left" }, [
              _c("div", [_c("haerd-left")], 1),
              _c(
                "div",
                { staticStyle: { width: "100%", padding: "20px 0px" } },
                [_c("echarts-zhe")],
                1
              ),
              _c("div", [
                _c("div", { staticClass: "title-tobsd" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [_c("haerd-bot")],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "flex-rigit" }, [_c("haerd-rigth")], 1),
          ])
        : _c("div", { staticClass: "info" }, [
            _vm._v("欢迎使用商单公司数字化管理系统"),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-tob" }, [
      _c("h3", [_vm._v("基础数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }