<!--
 * @Author: 候怀烨
 * @Date: 2020-11-23 19:42:03
 * @LastEditTime: 2021-04-26 09:26:10
 * @LastEditors: Please set LastEditors
 * @Description: 弹窗
 * @FilePath: \sd-vue-admin\src\views\index\components\PopUps.vue
-->
<template>
  <div>
    <el-button
      style="font-size: 20px"
      type="text"
      @click="dialogVisible = true"
    >
      开帐向导
    </el-button>

    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="username + ',您好！欢迎使用商单4.0企业管理系统'"
      :visible.sync="dialogVisible"
      width="80%"
      center
    >
      <div>
        <p>为了方便您更好地使用本系统，请先配置以下项目：</p>
        <div class="flexs">
          <el-table stripe border :data="tableData">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column label="主要步骤" prop="name" align="center">
              <template #default="{ row, $index }">
                <div>
                  <a v-if="row.url" target="_blank" :href="row.url">
                    {{ row.name }}
                  </a>
                  <!-- <router-link v-if="row.url" :to="row.url">
                    {{ row.name }}
                  </router-link> -->
                  <div v-else-if="$index == 4"></div>
                  <a v-else style="cursor: pointer" @click="batchImport(row)">
                    {{ row.name }}
                  </a>
                </div>
                <div
                  v-if="$index == 2 || $index == 1"
                  style="display: inline-block; cursor: pointer"
                  @click="batchImport(row)"
                >
                  批量导入
                  <i class="el-icon-upload"></i>
                </div>
                <div v-if="$index == 0">
                  <!-- (批量导入前请先创建好销售区域、渠道、客户等级) -->
                </div>
                <div v-if="$index == 1">
                  <!-- (批量导入前请先创建好品牌、品类以及客户渠道信息) -->
                </div>
                <div v-if="$index == 3">
                  (导入前请先创建好供应商、仓库和商品)
                </div>
                <div v-if="$index == 4">
                  <a style="cursor: pointer" @click="batchImport(row)">
                    导入客户欠款
                  </a>
                  <div>(导入前请先创建好客户、业务员)</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="相关事项"
              width=""
              prop="xaingqing"
              align="center"
            >
              <template #default="{ row, $index }">
                <div
                  v-for="item in row.xaingqing"
                  :key="item"
                  style="margin-left: 10px; text-align: left"
                >
                  <div v-if="$index == 4">
                    <!-- <a style="cursor: pointer" @click="importClient">
                      导入客户欠款：
                    </a>
                    使用表单导入以前形成的欠款 -->
                  </div>
                  <div v-else v-html="item"></div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="notshow">不显示此页</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="suubmi">确 定</el-button>
      </span>
      <import-tpl ref="importTpl"></import-tpl>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { postAction } from '@/api/Employee'
  import ImportTpl from './importTpl'
  export default {
    // beforeRouteLeave(to, from, next) {
    //   alert(1)
    //   next()
    // },
    components: { ImportTpl },
    data() {
      return {
        dialogVisible: false,
        form: {
          depart_name: '',
          role_data: [],
        },
        cities: [],
        tableData: [
          {
            name: '创建员工',
            url: '/#/baseOrder/aboutSatff/satffDetail',
            xaingqing: [
              '<a target="_blank" href="/#/baseOrder/aboutSatff/departmentDetail">添加部门：</a>贵司如有多个部门，请先添加部门',
              '<a target="_blank" href="/#/baseOrder/aboutSatff/pathPlanning">拜访线路：</a>设置客户的拜访线路',
              '<a target="_blank" href="/#/baseOrder/aboutSatff/pathAllocation">线路分配：</a> 设置员工的拜访线路及拜访计划',
            ],
          },
          {
            name: '创建客户',
            url: '/#/baseOrder/aboutClient/clientDetail',
            xaingqing: [
              '<a target="_blank" href="/#/baseOrder/aboutClient/saleArea">添加销售区域</a>：如在多个地区开展业务，请先添加销售区域',
              '<a target="_blank" href="/#/baseOrder/aboutClient/channel">添加渠道</a>：客户如有多种渠道类型，请先添加渠道',
              '<a target="_blank" href="/#/baseOrder/aboutClient/clientLevel">添加客户等级：</a>对客户进行精细化管理，请先添加客户等级',
            ],
          },
          {
            name: '创建商品',
            url: '/#/baseOrder/aboutGoods/goodsDetail',
            xaingqing: [
              '<a target="_blank" href="/#/baseOrder/aboutGoods/units">设置单位：</a>设置商品大、中、小包装常用单位',
              '<a target="_blank" href="#/baseOrder/aboutGoods/brandDetail">添加品牌</a>：商品有多种品牌，请先添加品牌。',
              '<a target="_blank" href="/#/baseOrder/aboutGoods/categoryDetail">添加分类：</a>商品有多种分类，请先添加分类。',
            ],
          },

          {
            name: '导入期初库存',
            xaingqing: [
              '<a target="_blank" href="/#/baseOrder/aboutGoods/depotManagement">添加仓库：</a>管理实体仓库和车辆',
              '<a target="_blank" href="/#/baseOrder/aboutGoods/supplier2">添加供应商：</a>管理供应商',
            ],
          },
          {
            name: '导入客户欠款',
            xaingqing: ['自定义'],
          },
          {
            name: '其他设置',
            xaingqing: [
              '<a target="_blank" href="/#/performance/visitManagement/visitPictures">拍照类型设置：</a>设置拜访拍照、陈列拍照的常用照片类型',
              '<a target="_blank" href="/#/setting/otherManagement/receivePayment">收款方式设置：</a>设置业务员下单时常用的收款方式',
              '<a target="_blank" href="/#/setting/otherManagement/accounting">会计科目设置：</a>设置公司会计科目',
              '<a target="_blank" href="/#/setting/otherManagement/attendance">考勤分组设置：</a>设置公司员工考勤分组',
              '<a target="_blank" href="/#/setting/otherManagement/operating">业务流程设置：</a>设置经营相关的流程开关',
              '<a target="_blank" href="/#/setting/printSet">打印模板设置：</a>设置后台打印的单据模板',
            ],
          },
        ],
        url: {
          guide: '/baseAdmin/index/guide',
          doguide: '/baseAdmin/index/do-guide',
          show: '/baseAdmin/index/guide-status',
        },
      }
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        username: 'user/username',
      }),
    },
    watch: {
      $route: {
        handler(route) {
          console.log(route)
          this.dialogVisible = false
        },
        // immediate: true,
      },
    },
    mounted() {
      this.handlerData()
    },
    methods: {
      // lli
      batchImport(row) {
        console.log(row)
        //1商品2客户3盘点数据4期初库存5期初欠款
        if (row.name == '创建客户') {
          this.$refs.importTpl.data.type = 2
          this.$refs.importTpl.title = '批量导入客户'
          this.$refs.importTpl.showDialog = true
        } else if (row.name == '创建商品') {
          this.$refs.importTpl.data.type = 1
          this.$refs.importTpl.title = '批量导入商品'
          this.$refs.importTpl.showDialog = true
        } else if (row.name == '导入期初库存') {
          console.log('导入期初库存')
          this.$refs.importTpl.data.type = 4
          this.$refs.importTpl.title = '批量导入期初库存'
          this.$refs.importTpl.showDialog = true
        } else if (row.name == '导入客户欠款') {
          console.log('导入客户欠款')
          this.$refs.importTpl.data.type = 5
          this.$refs.importTpl.title = '批量导入客户欠款'
          this.$refs.importTpl.showDialog = true
        } else {
          return
        }
      },
      // 导入客户欠款
      importClient() {
        console.log('123')
      },
      // endlii
      notshow() {
        postAction(this.url.show, { close_guide: 1 })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.dialogVisible = false
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      suubmi() {
        if (this.form.role_data.length <= 0) {
          this.form.role_data = ''
        } else {
          this.form.role_data = JSON.stringify(this.form.role_data)
        }
        postAction(this.url.doguide, this.form)
          .then((res) => {
            console.log(res, '')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerData() {
        postAction(this.url.guide, {})
          .then((res) => {
            console.log(res, '选择数据成功')
            this.cities = res.data.role_list
            if (!res.data.close_guide) {
              this.dialogVisible = true
            } else {
              this.dialogVisible = false
            }
          })
          .catch((err) => {
            console.log(err, '选项数据失败')
          })
      },
      handleClose(done) {
        done()
      },
    },
  }
</script>

<style>
  .flexs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .guit {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
  }
  .gaodu {
    height: 280px;
    overflow: auto;
  }
  .el-table .cell {
    white-space: pre-line;
  }
</style>
